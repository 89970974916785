import React from "react";
import dynamic from "next/dynamic";
import sendGaEvent from "@gcloud-npm/utils.metrics.send-ga-component-event";
import { ENV_NAME } from "@gcloud-npm/utils.enums.environment";
import FrontSonda from "@gcloud-npm/utils.monitoring.front-sonda";
import getDeviceKindDependsOnViewPort from "@gcloud-npm/utils.common.get-device-kind-depends-on-viewport";

export const currentEnv = `${process.env.NEXT_PUBLIC_ENV_NAME}`;

const ENTER_KEY = "enter";
const RIGHT_MOUSE_BUTTON = 2;

const CMS_MODAL_PAGE = "cms-modal-page:";
const CMS_MODAL_CONTENT = "cms-modal:";
const ANCHOR = "anchor:";
const NONE = "enlace:";

export const BEHAVIOUR_KIND = {
  NONE,
  CMS_MODAL_PAGE,
  CMS_MODAL_CONTENT,
  ANCHOR,
};

export const appendHost = (href = "") => {
  let host = "";
  if (href.indexOf("http") === -1 && href.indexOf("//") === -1) {
    switch (currentEnv) {
      case ENV_NAME.DEV:
      case ENV_NAME.LOCAL:
        host = `http://127.0.0.1:8089`;
        break;
      case ENV_NAME.CERT:
      case ENV_NAME.INTE:
        host = `https://www.movistar.es.cert`;
        break;
      case ENV_NAME.CODE:
        host = `https://dev.ssr.col.movistar.es`;
        break;
      case ENV_NAME.QA_SSR:
        host = `https://www.movistar.es/qa`;
        break;
      case ENV_NAME.DEV_SSR:
        host = `https://www.movistar.es/develop`;
        break;
      case ENV_NAME.PROD:
        host = `https://www.movistar.es`;
        break;
    }
  }

  if (
    href &&
    href.indexOf("#") === -1 &&
    href.indexOf("?") === -1 &&
    href.indexOf(".") === -1 &&
    href[href.length - 1] !== "/"
  ) {
    href = `${href}/`;
  }
  return `${host}${href}`;
};

export const getReadyLinkForDev = (href) => {
  let devLink = href;
  const projectBasePath = `${process.env.NEXT_PUBLIC_PROJECT_BASE_PATH}`;
  // eliminamos el basename del proyecto
  if (
    projectBasePath !== "/" &&
    href.indexOf("http") === -1 &&
    href.indexOf("//") === -1
  ) {
    devLink = href.replace(`${process.env.NEXT_PUBLIC_PROJECT_BASE_PATH}`, "");
  }

  if (!devLink) {
    devLink = "/";
  }

  if (
    devLink &&
    devLink.indexOf("#") === -1 &&
    devLink.indexOf("?") === -1 &&
    devLink.indexOf(".") === -1 &&
    devLink[devLink.length - 1] !== "/"
  ) {
    devLink = `${devLink}/`;
  }

  return devLink;
};

export const checkIfHasHrefOperators = (href) => {
  let result = false;
  const OPERATORS = ["tel:", "mailto:"];
  OPERATORS.forEach((item) => {
    if (!result && href && href.indexOf(item) > -1) {
      result = true;
    }
  });
  return result;
};

const properKeyChecker = (e) =>
  e && e.code && e.code.toLowerCase() === ENTER_KEY;

const properMouseButtonChecker = (e) =>
  (e && e.which && e.which !== RIGHT_MOUSE_BUTTON) ||
  (e && e.button && e.button !== RIGHT_MOUSE_BUTTON);

const wrapperFunction = ({ action2Execute = null, taggingInfo }) => {
  try {
    const isFrontApp = typeof window !== "undefined";
    if (isFrontApp && sendGaEvent) {
      sendGaEvent(taggingInfo);
    }
    if (action2Execute) {
      action2Execute();
    }
  } catch (error) {
    let info = "";
    try {
      info = `Element taggingInfo: ${JSON.stringify(taggingInfo)}`;
    } catch (e) {
      console.error("No se pudo generar más info en base al taggingInfo");
    }
    const sonda = new FrontSonda({
      processName: `Custom-link-${process.env.NEXT_PUBLIC_PROJECT_ID}`,
      filePath: `Custom-link-${window.location.href}`,
    });
    sonda.errorMessage(error);

    console.error({ action2Execute });
    throw error;
  }
};

export const anchorFunction = ({ href }) => {
  try {
    const anchor = href.split(":")[1];
    const element = document.querySelector(anchor);
    if (element && element.scrollIntoView) {
      element.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  } catch (e) {
    console.error(e);
  }
};

export const generateOnHandlerMethods = ({
  onClick,
  taggingInfo,
  disabled,
  isCmsModalPageHref,
  isCmsModalContentHref,
  isAnchor,
  alias,
  context,
  addModal,
  href,
  additionalContent,
  additionalContentHeader,
}) => {
  let newOnClick = null;
  let newOnKeyDown = null;
  let newOnMouseDown = null;
  let action2Execute = () => {};

  /* ---------------------------- PARTE MODALFICHA ---------------------------- */
  // isCmsModalPageHref && onClick
  if (isCmsModalPageHref && onClick) {
    action2Execute = () => {
      cmsPageModalHandler({ href, addModal });
      onClick();
    };
  }
  // isCmsModalPageHref && !onClick
  if (isCmsModalPageHref && !onClick) {
    action2Execute = () => {
      cmsPageModalHandler({
        href,
        addModal,
        additionalContent,
        additionalContentHeader,
      });
    };
  }
  /* ----------------------------- PARTE MODAL CMD ---------------------------- */
  if (isCmsModalContentHref && onClick) {
    action2Execute = () => {
      cmsModalContentHandler({ alias, addModal, context, href });
      onClick();
    };
  }
  if (isCmsModalContentHref && !onClick) {
    action2Execute = () => {
      cmsModalContentHandler({ alias, addModal, context, href });
    };
  }
  /* ------------------------------ PARTE ANCHOR ------------------------------ */
  if (isAnchor) {
    action2Execute = () => {
      anchorFunction({ href });
    };
  }

  /* ---------------------------- onclick sin extra --------------------------- */
  if (onClick && (!isCmsModalContentHref || !isCmsModalPageHref)) {
    action2Execute = () => {
      onClick();
    };
  }

  newOnClick = (e) => {
    taggingInfo = {
      ...taggingInfo,
      eventType: "click",
    };
    wrapperFunction({ taggingInfo, action2Execute });
  };
  newOnKeyDown = (e) => {
    if (properKeyChecker(e)) {
      //Evita ejecuciones duplicadas del evento enter
      e.preventDefault();
      taggingInfo = {
        ...taggingInfo,
        eventType: "enter",
      };
      wrapperFunction({ taggingInfo, action2Execute });
    }
  };
  newOnMouseDown = (e) => {
    if (properMouseButtonChecker(e)) {
      taggingInfo = {
        ...taggingInfo,
        eventType: "clickMouseWheel",
      };
      wrapperFunction({ taggingInfo, action2Execute });
    }
  };

  if (disabled) {
    newOnClick = () => {
      return false;
    };
    newOnKeyDown = () => {
      return false;
    };
    newOnMouseDown = () => {
      return false;
    };
  }
  return {
    newOnClick,
    newOnKeyDown,
    newOnMouseDown,
  };
};

export const extendsAccesibility = ({
  cmpTitle,
  cmpAriaLabel,
  cmpScreenRedable,
  taggingInfo,
}) => {
  let title = cmpTitle;
  let ariaLabel = cmpAriaLabel;
  const ariaHidden = !cmpScreenRedable;

  if (!title && taggingInfo && taggingInfo.componentContent) {
    title = taggingInfo.componentContent;
  }

  if (!ariaLabel && taggingInfo && taggingInfo.componentContent) {
    ariaLabel = taggingInfo.componentContent;
  }

  if (title === ariaLabel) {
    title = null;
  }

  return {
    title,
    ariaLabel,
    ariaHidden,
  };
};

export const cmsPageModalHandler = ({
  href,
  addModal,
  additionalContent,
  additionalContentHeader,
}) => {
  try {
    const url = href.split(":")[1];
    if (url && window.top.location === window.self.location) {
      const device = getDeviceKindDependsOnViewPort();
      const ModalWeb = dynamic(() =>
        import("@/bit/components/components.cms-block.cms-modal-page")
      );
      addModal({
        id: `cms-modal-page${url.replace(/\//g, "--")}`,
        Element2Show: ModalWeb,
        modalProps: {
          closeIdByClickingOutside: "",
          opacityLayoutColor: "blur",
          closeByClickingOutside: true,
          contentPosition:
            device === "MOBILE" ? "center--bottom" : "center--center",
          inSceneEffect:
            device === "MOBILE" || device === "TABLET"
              ? "fadeInBottom"
              : "fadeInRight",
          outSceneEffect:
            device === "MOBILE" || device === "TABLET"
              ? "fadeOutBottom"
              : "fadeOutRight",
          scrollAllowed: false,
          createAnchor: false,
        },
        elementProps: {
          url,
          additionalContent,
          additionalContentHeader,
        },
      });
    } else if (url && window.top.location !== window.self.location) {
      const message = {
        url,
        action: "redirect",
      };
      window.parent.postMessage(message, "*");
    }
  } catch (e) {
    console.error(e);
  }
};

const isEditorEnv = () => {
  return (
    `${process.env.NEXT_PUBLIC_ENV_NAME}` === "local" ||
    `${process.env.NEXT_PUBLIC_ENV_NAME}` === "qa_ssr"
  );
};
const MODAL_PROPS = {
  closeIdByClickingOutside: "",
  contentPosition: "right--top",
  opacityLayoutColor: "blur",
  inSceneEffect: "fadeIn",
  contentInsideContainer: false,
  outSceneEffect: "fadeOut",
  closeByClickingOutside: true,
  scrollAllowed: false,
  createAnchor: false,
};

export const cmsModalContentHandler = async ({
  alias,
  addModal,
  context,
  href,
}) => {
  let modalResponse = null;
  let ModalComponent = null;
  let CmsBlock = null;
  try {
    let modalId = "";
    let orionCmsAlias = ";";

    if (href && href.indexOf(":") > 0) {
      const tokensHref = href.split(":");
      if (tokensHref && tokensHref[1]) {
        orionCmsAlias = tokensHref[1];
      }

      if (tokensHref && tokensHref[2]) {
        modalId = tokensHref[2];
      }
    }

    const modalAlias = `${alias}-modales`;
    ModalComponent = dynamic(() =>
      import("@/bit/components/components.cms-block.cms-modal")
    );
    CmsBlock = dynamic(() => import("@/bit/components/components.cms/cms-block"));
    let cmsPromiseModule = await import("@gcloud-npm/adabyron.page.get-props");
    cmsPromiseModule = cmsPromiseModule.default;
    modalResponse = await cmsPromiseModule({
      orionCmsAlias,
      context,
      preRenderEnv: `${
        process.env.NEXT_PUBLIC_ENV_NAME !== "prod" ? "qa_ssr" : "prod"
      }`,
      headerLocation: "",
      modalId,
      cms: isEditorEnv(),
      build: false,
    });

    modalResponse = modalResponse.orion;
    // si viene de la contribución sabemos que pestaña abrir, sino el back nos devolverá la primera
    const finalModalId = modalId || modalResponse.modalId;
    let modalProps = MODAL_PROPS;
    // Vemos si tienemos indicadas propiedades específicas
    if (
      modalResponse &&
      modalResponse[finalModalId] &&
      modalResponse[finalModalId]["cms-modal-props"]
    ) {
      // Ya no borramos el cms de modal props para poder contribuirlo
      modalProps = modalResponse[finalModalId]["cms-modal-props"];
    }

    // añadimos funcion de cierre de modal
    if (modalResponse[finalModalId]) {
      modalResponse[finalModalId] = {
        ...modalResponse[finalModalId],
        modalAlias,
        modalId,
        onClose: () => {
          addModal({ id: "" });
        },
      };
    }

    addModal({
      id: finalModalId,
      Element2Show: () => (
        <CmsBlock
          alias={modalAlias}
          modalId={finalModalId}
          orionInfo={modalResponse}
          blockName={finalModalId}
          Component={ModalComponent}
        />
      ),
      modalProps,
    });
  } catch (e) {
    console.error(e);
  }
};
