/* eslint-disable no-plusplus */
/* eslint-disable prettier/prettier */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-param-reassign */
import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { actions as modalActions } from "@/bit/reducers/reducers.modals-reducer";
import dynamic from "next/dynamic";
import {
  BEHAVIOUR_KIND,
  generateOnHandlerMethods,
  extendsAccesibility,
  appendHost,
  getReadyLinkForDev,
  currentEnv,
  checkIfHasHrefOperators,
} from "./utils";

const IconGlyph = dynamic(() => import("@/bit/components/components.icon-glyph"));

/**
 * Componente que permite hacer cualquier elemento un componente clickable.
 */
const NextLink = (props) => {
  const {
    id,
    children,
    className,
    internal,
    taggingInfo,
    title: cmpTitle,
    target,
    ariaLabel: cmpAriaLabel,
    screenReadable: cmpScreenRedable,
    cms,
    cmsHandler = null,
    style,
    buttonParent,
    buttonTypeHtml,
    disabled,
    cyData,
    alias,
    context,
    addModal,
    additionalContent,
    additionalContentHeader,
  } = props;
  let { href, onClick } = props;

  const { title, ariaLabel, ariaHidden } = extendsAccesibility({
    cmpTitle,
    cmpAriaLabel,
    cmpScreenRedable,
    taggingInfo,
  });
  const isCmsModalPageHref =
    href && href.indexOf(BEHAVIOUR_KIND.CMS_MODAL_PAGE) > -1;
  const isCmsModalContentHref =
    href && href.indexOf(BEHAVIOUR_KIND.CMS_MODAL_CONTENT) > -1;
  const isAnchor = href && href.indexOf(BEHAVIOUR_KIND.ANCHOR) > -1;

  let currentClassName = `next-link${className ? ` ${className}` : ""}`;
  isCmsModalPageHref ? (currentClassName += " hiddenNavigation") : "";
  const finalCssName = !children
    ? `${currentClassName} nextLinkOverBox`
    : currentClassName;

  const {
    newOnClick: onClickHandler,
    newOnKeyDown: onKeyDownHandler,
    newOnMouseDown: onMouseDownHandler,
  } = generateOnHandlerMethods({
    onClick,
    context,
    taggingInfo,
    disabled,
    isAnchor,
    isCmsModalPageHref,
    isCmsModalContentHref,
    addModal,
    alias,
    href,
    additionalContent,
    additionalContentHeader,
  });

  // personalizo
  if (cmsHandler) {
    currentClassName = `${currentClassName} element-editable`;
    if (children) {
      return (
        <>
          <div
            onClick={() =>
              cmsHandler({
                ...props,
                name: "custom-link",
              })
            }
            className={`${currentClassName} svg-layout-size cms-custom-link-placeholder`}
          >
            <IconGlyph id="anchor-link-enlace-m" height={20} color="white" />
          </div>
          {children}
        </>
      );
    }
    return (
      <span
        className={`${currentClassName} svg-layout-size cms-custom-link-no-children-placeholder`}
        onClick={
          cms && cmsHandler
            ? () =>
                cmsHandler({
                  ...props,
                  name: "custom-link",
                })
            : null
        }
      >
        <IconGlyph id="anchor-link-enlace-m" height={20} color="white" />
      </span>
    );
  }

  // Devolvemos children en el cms cuando no hay enlace
  if (cms && children && href === "" && !onClick) {
    return children;
  }

  const renderAsButton =
    (!href && onClick) ||
    buttonTypeHtml === "submit" ||
    isCmsModalPageHref ||
    isAnchor ||
    isCmsModalContentHref;
  // 0) vemos si tiene href sino tiene solo es un elemento para taggear (TODO)
  if (renderAsButton) {
    return (
      <button
        id={id || null}
        type={buttonTypeHtml}
        className={finalCssName}
        tabIndex={0}
        aria-label={ariaLabel}
        aria-hidden={ariaHidden}
        title={title}
        onClick={onClickHandler}
        onKeyDown={onKeyDownHandler}
        onMouseDown={onMouseDownHandler}
        disabled={disabled}
        style={style}
        data-cy={cyData}
      >
        {buttonParent && <div className="hover-layer" />}
        {children}
        <style jsx global>{`.nextLinkOverBox {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
}
`}</style>
      </button>
    );
  }

  const hasOperators = checkIfHasHrefOperators(href);
  // "2 ) logica de link as usual"
  if (
    !internal &&
    currentEnv === "dev" &&
    !hasOperators &&
    !isCmsModalContentHref
  ) {
    href = getReadyLinkForDev(href);
  }

  if (!internal && !hasOperators && href && !isCmsModalContentHref) {
    href = appendHost(href);
  }

  if (!href && !onClick) {
    return null;
  }

  return (
    <a
      id={id || null}
      className={finalCssName}
      tabIndex={0}
      aria-label={ariaLabel}
      aria-hidden={ariaHidden}
      title={title}
      href={href}
      target={target}
      rel={target === "_blank" ? "noopener noreferrer" : ""}
      onClick={onClickHandler}
      onKeyDown={onKeyDownHandler}
      onMouseDown={onMouseDownHandler}
      disabled={disabled}
      style={style}
      data-cy={cyData}
    >
      {buttonParent && <div className="hover-layer" />}
      {children}
      {/* INJECT_STYLED_JSX_TAG_GLOBAL */}
    </a>
  );
};

const mapStateToProps = (state) => ({
  context: state.main.context,
  alias: state.main.orionAlias,
});

const mapDispatchToProps = (dispatch) => ({
  addModal: (data) => dispatch(modalActions.addModal(data)),
});

NextLink.propTypes = {
  /**
   * {
   *  "info": "Id del componente, para enlazar un modal escriba aliasorion:cms-id-modal",
   *  "kind": "both",
   *  "beautifulName": "Id"
   * }
   */
  id: PropTypes.string,
  /**
   * {
   *  "info": "Url a la que se accederá tras hacer click en el elemento",
   *  "kind": "both",
   *  "beautifulName": "Enlace"
   * }
   */
  href: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  /**
   * {
   *  "info": "Función js que se ejecutará al hacer click",
   *  "kind": "dev",
   *  "beautifulName": "onClick"
   * }
   */
  onClick: PropTypes.func,
  /**
   * {
   *  "info": "Classnames que heredará el componente, sirve para aplicar estilos",
   *  "kind": "dev",
   *  "beautifulName": "ClassName"
   * }
   */
  className: PropTypes.string,
  /**
   * {
   *  "info": "Información para el tagueado del componente",
   *  "kind": "dev",
   *  "beautifulName": "Información de tagueado"
   * }
   */
  taggingInfo: PropTypes.object.isRequired,
  /**
   * {
   *  "info": "Lugar donde se abrirá el enlace",
   *  "kind": "dev",
   *  "beautifulName": "Href target"
   * }
   */
  target: PropTypes.string,
  /**
   * {
   *  "info": "Texto que se muestra al pasar el ratón por encima del componente. Primero utiliza taggingInfo.componentContent y si es nulo utiliza este atributo",
   *  "kind": "both",
   *  "beautifulName": "Title"
   * }
   */
  title: PropTypes.string,
  /**
   * {
   *  "info": "Proporciona una descripción precisa y concisa del contenido del elemento para usuarios de tecnologías de asistencia, como lectores de pantalla, que no pueden ver o interpretar el contenido visual. Primero utiliza taggingInfo.componentContent y si es nulo utiliza este atributo",
   *  "kind": "dev",
   *  "beautifulName": "AriaLabel"
   * }
   */
  ariaLabel: PropTypes.string,
  /**
   * {
   *  "info": "Deshabilita el componente (solo en ocasiones que se utiliza onClick)",
   *  "kind": "dev",
   *  "beautifulName": "Deshabilitado"
   * }
   */
  disabled: PropTypes.bool,
  /**
   * {
   *  "info": "Cuando se establece en true, indica que el elemento no debe ser presentado visualmente o leído por el lector de pantalla",
   *  "kind": "dev",
   *  "beautifulName": "Visible solo para lectores de pantalla"
   * }
   */
  screenReadable: PropTypes.bool,
  /**
   * {
   *  "info": "Valor del type que cobrará el button en el DOM",
   *  "kind": "dev",
   *  "beautifulName": "Type valor Dom"
   * }
   */
  buttonTypeHtml: PropTypes.string,
  /**
   * {
   *  "info": "Parametriza si el customlink envuelve un button o no",
   *  "kind": "dev",
   *  "beautifulName": "Button es componente padre"
   * }
   */
  buttonParent: PropTypes.bool,
  /**
   * {
   *  "info": "Estilos js para componente button",
   *  "kind": "dev",
   *  "beautifulName": "Estilos"
   * }
   */
  style: PropTypes.object,
  /**
   * {
   *  "info": "Cadena que identifica inequívocamente el componente, para finalidades de testing",
   *  "kind": "dev",
   *  "beautifulName": "cyData"
   * }
   */
  cyData: PropTypes.string,
  /**
   * {
   *  "info": "Marca si un enlace es interno, por defecto siempre serán externos",
   *  "kind": "dev",
   *  "beautifulName": "Enlace externo"
   * }
   */
  internal: PropTypes.bool,
  /**
   * {
   *  "info": "Objeto con información sobre las propiedades necesarias (id, timestamp...) para editar los valores en orion.",
   *  "kind": "dev",
   *  "beautifulName": "CMS"
   * }
   */
  cms: PropTypes.object,
  /**
   * {
   *  "info": "Handler para eventos del CMS. Se ejecuta cuando se clicka en el componente desde el CMS.",
   *  "kind": "dev",
   *  "beautifulName": "CMS Handler"
   * }
   */
  cmsHandler: PropTypes.func,
};

NextLink.defaultProps = {
  id: null,
  className: "",
  target: "_self",
  screenReadable: true,
  disabled: false,
  onClick: null,
  buttonTypeHtml: "button",
  buttonParent: false,
  style: {},
  cyData: "",
  internal: false,
  cms: null,
  cmsHandler: null,
};

export default connect(mapStateToProps, mapDispatchToProps)(NextLink);
